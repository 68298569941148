import axios from "axios";
import { CONFIG } from "../config/config.js";

// let token = "9cf2652f3a4cfa1f4170b4535383b1a0dad31ab8";

// 7cbf00f5d5998590a6b9993b5eacc78057796c8f

// let token = "7cbf00f5d5998590a6b9993b5eacc78057796c8f";

let token = "";

if(typeof window !== "undefined") {
  if (localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }
}

export const http = axios.create({
  baseURL: CONFIG.API_ENDPOINT,
  timeout: 100000,
  headers: {
    Accept: "application/json",
    Authorization: token ? `Token ${token}` : null,
  },
});
