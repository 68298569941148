import React, { useEffect, useState } from "react";
import style from './Payment-request.module.scss';
import Container from "../container";
import PaymentRequestTab from "../payment-request-tab";
import PaymentRequestFilter from "../payment-filter";
import Search from "../search";
import { http } from '../../utilits/http';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../pagination";

const PaymentRequest = () => {
    const [tabOption, setTabOption] = useState("customer");
    const [ data , setData ] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams(); 
    const [filterOptions, setFilterOptions] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [checked, setChecked] = useState(null);
    const itemsPerPage = 15;
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleGetData = () => {
        const requestStatus = searchParams.get('request_status') || 'all'; 
        http.get(`user/payment-request-admin?user_type=${tabOption}&request_status=${requestStatus}&page=${currentPage + 1}`)
        .then((res) => {
            setData(res?.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        handleGetData();   
    },[tabOption , searchParams])

    const handleOptionSelect = (optionValue) => {
        setChecked(optionValue);
        setFilterOptions(false);
        setSearchParams({ request_status: optionValue }); 
    };

    const handleComplete = () => {
        handleGetData();
    };

    const pageCount = Math.ceil(data.count / itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
        navigate(`${location.pathname}?page=${selectedPage.selected + 1}`);
    };

    return(
        <Container>
            <div className={style.payment_request_container}>
                <div className={style.head_wrapper}>
                    <div className={style.filter}>
                        <PaymentRequestFilter 
                            filterOptions={filterOptions} 
                            setFilterOptions={setFilterOptions}
                            checked={checked}
                            setChecked={setChecked}
                            handleOptionSelect={handleOptionSelect}
                        />
                    </div>
                    {/* <div className={style.search}>
                        <Search page={true} />
                    </div> */}
                </div>
                <div className={style.content_wrapper}>
                    <div className={style.head}>Payment Request</div>
                    <div className={style.tab_wrapper}>
                        <PaymentRequestTab 
                            tabOption={tabOption} 
                            setTabOption={setTabOption} 
                            data={data?.results}
                            onComplete={handleComplete} 
                        />
                    </div>
                </div>
                {pageCount > 1 && (
                    <div>
                        <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
                    </div>
                )}
            </div>
        </Container>
    )
}
export default PaymentRequest;